import gql from "graphql-tag";
import { CTAFields, FlagFields, ProductCardsFields } from "./fragments";
import { CatalogStackFragments } from "./stack_fragments";

export const CatalogQuery = gql`
  ${CTAFields}
  ${FlagFields}
  ${ProductCardsFields}
  ${CatalogStackFragments}
  query CATALOG_QUERY($locale: String, $catalogSlug: String!) {
    catalogs(locale: $locale, where: { slug: $catalogSlug }) {
      id
      primary
      slug
      breadcrumbTitle
      breadcrumbSlug
      collections {
        primary
        slug
        productSelector {
          id
          product {
            id
            enable
            title
            slug
            variantType
            frame
            productCardDescription
            includeBNPLOptions
            explodeVariants
            currency {
              name
              code
              symbol
            }
            variants {
              enable
              primaryColorHex
              id
              title
              slug
              price
              primaryColorHex
              compareAtPrice
              defaultVariant
              enable
              outOfStock
              productCardGroupLabel
              additionalPriceMessage
              salePriceText
              salePriceTextModal {
                primary
                secondary
                type
                media {
                  url
                  alternativeText
                }
                additionalTexts {
                  text
                }
                ctas {
                  text
                  event
                  location
                  type
                  rightIcon
                  action
                }
              }
              customCta {
                enable
              }
              photos {
                name
                ext
                provider_metadata
                alternativeText
                url
              }
              productShape {
                label
                text
              }
              productFabric {
                label
                text
              }
              productFrame {
                label
                text
              }
              productOption {
                label
                text
              }
              productOption2 {
                label
                text
              }
            }
            productCards {
              ...ProductCardsFields
            }
            flags {
              ...FlagFields
            }
            productType {
              name
              label
            }
            productCollection {
              text
              label
            }
            seats
          }
          contentCard {
            link
            action
            modal {
              primary
              secondary
              type
              media {
                url
                alternativeText
              }
              additionalTexts {
                text
              }
              ctas {
                text
                event
                location
                type
                rightIcon
                action
              }
            }
            mediaLink
            mediaTranscriptUrl
            pageModalSlug
            modalMedia {
              url
              width
              height
            }
            sharedGalleries {
              images {
                url
                alternativeText
                caption
                height
                width
                name
              }
            }
            productGallery {
              variants {
                defaultVariant
                photos {
                  url
                  alternativeText
                  caption
                  height
                  width
                  name
                }
                sharedGalleries {
                  images {
                    url
                    alternativeText
                    caption
                    height
                    width
                    name
                  }
                }
              }
            }
            media {
              url
              width
              height
              provider_metadata
              alternativeText
            }
            mobileMedia {
              url
              width
              height
              provider_metadata
              alternativeText
            }
            mediumMedia {
              url
              width
              height
              provider_metadata
              alternativeText
            }
          }
        }
      }
      filters {
        filterBy
        label
      }
      sorters {
        label
        sorterBy
      }
      stack {
        __typename
        ... on ComponentStackSingleAccordion {
          id
          title
          content
        }
        ... on ComponentStackSeo {
          ...ComponentStackSeoFields
        }
        ... on ComponentStackHero {
          ...ComponentStackHeroFields
        }
        ... on ComponentStackTrustSignalContentBlock {
          ...ComponentStackTrustSignalContentBlockFields
        }
      }
    }
  }
`;
