import React from "react";
import { graphql } from "gatsby";
import withLayout from "../../hoc/withLayout/withLayout";

import { buildComponentStack } from "../../utils/StackBuilder/StackBuilder";
import HomeStructuredData from "../../components/StructuredData/HomeStructuredData";

type PageData = any;

interface PageProps {
  pageContext: {
    layoutData: { promotions: Promotion[] };
  };
  data: {
    alamedaapi: {
      pages: PageData;
    };
  };
}

function Page({
  pageContext,
  data: {
    alamedaapi: { pages },
  },
}: PageProps) {
  if (!pages) return null;

  if (pages.length != 1) {
    // There can only be one page per query.

    console.error(
      `There were ${pages.length}  pages returned from the backend`
    );
  }

  const componentStack = buildComponentStack(pages[0].stack);

  const isHomePage = pages[0]?.slug === "";

  return (
    <>
      {isHomePage && <HomeStructuredData />}
      <div>{componentStack}</div>
    </>
  );
}

export default withLayout(Page);

export const pageQuery = graphql`
  query PAGE_QUERY($locale: String, $pageId: ID!) {
    alamedaapi {
      pages(locale: $locale, where: { id: $pageId }) {
        id
        slug
        stack {
          __typename
          ... on AMAPI_ComponentStackSeo {
            ...ComponentStackSeoFields
          }
          ... on AMAPI_ComponentStackPressLogos {
            ...ComponentStackPressLogosFields
          }
          ... on AMAPI_ComponentStackAffirmSection {
            id
            included
            product {
              title
            }
          }
          ... on AMAPI_ComponentStackTwoColumnDescription {
            id
            primary
            invert
            body
          }
          ... on AMAPI_ComponentStackOkendoReview {
            id
            okendoReview {
              collectionId
              productId
            }
          }
          ... on AMAPI_ComponentStackShowroomsFinder {
            enable
          }
          ... on AMAPI_ComponentStackMasonryHero {
            id
            title
            pretitle
            pretitleModalText
            pretitleModalMedia {
              url
              alternativeText
            }
            description
            buttonCta
            buttonCtaLink
            videoCta
            videoCtaLink
            galleryItems {
              media {
                url
                alternativeText
              }
            }
          }
          ... on AMAPI_ComponentStackInspirationGallery {
            id
            title
            subtitle
            galleryItems {
              inspirationObject {
                coverPhoto {
                  url
                  height
                  width
                  alternativeText
                }
                caption
                customerName
                customerLabel
                customerLocation
                visitableShowroom
                ctaPrimary
                ctaPrimaryLink
                ctaSecondary
                ctaSecondaryLink
                ctaPrimaryTooltip
                ctaSecondaryTooltip
                products {
                  displayVariantSlug
                  product {
                    title
                    slug
                    currency {
                      name
                      code
                      symbol
                    }
                    variants {
                      slug
                      title
                      price
                      photos {
                        url
                        alternativeText
                      }
                    }
                  }
                  customProduct {
                    title
                    price
                    slug
                    photo {
                      url
                      alternativeText
                    }
                  }
                }
              }
            }
          }
          ... on AMAPI_ComponentStackFaqSection {
            id
            faqs {
              id
              question
              answer
              priority
            }
          }
          ... on AMAPI_ComponentStackLeverJobPositionsBlock {
            id
            primary
            secondary
            tertiary
          }
          ... on AMAPI_ComponentStackMediaGallery {
            primary
            secondary
            gridImagesNumber
            medias: media {
              url
              alternativeText
              caption
              height
              width
              name
            }
            sharedGalleries {
              images {
                url
                alternativeText
                caption
                height
                width
                name
              }
            }
          }
          ... on AMAPI_ComponentStackPromotedCatalogBlock {
            ...ComponentStackPromotedCatalogBlockFields
          }
          ... on AMAPI_ComponentStackFeaturedParagraph {
            ...ComponentStackFeaturedParagraphFields
          }
          ... on AMAPI_ComponentStackPromotedTabbedFeature {
            ...ComponentStackPromotedTabbedFeatureFields
          }
          ... on AMAPI_ComponentStackTwoColumnsContentBlock {
            ...ComponentStackTwoColumnsContentBlockFields
          }
          ... on AMAPI_ComponentStackSingleAccordion {
            id
            title
            content
          }
          ... on AMAPI_ComponentStackTabSelector {
            id
            addToCardButton
            ctas {
              text
              location
              event
            }
          }
          ... on AMAPI_ComponentStackMediaCarousel {
            ...ComponentStackMediaCarouselFields
          }
          ... on AMAPI_ComponentStackMultiColumnContentBlock {
            ...ComponentStackMultiColumnContentBlockFields
          }
          ... on AMAPI_ComponentStackStaticTestimonialBlock {
            ...ComponentStackStaticTestimonialBlockFields
          }
          ... on AMAPI_ComponentStackStickyBar {
            ...ComponentStackStickyBarFields
          }
          ... on AMAPI_ComponentStackHero {
            ...ComponentStackHeroFields
          }
          ... on AMAPI_ComponentStackAccordion {
            ...ComponentStackAccordionFields
          }
          ... on AMAPI_ComponentStackTextBlock {
            ...ComponentStackTextBlockFields
          }
          ... on AMAPI_ComponentStackFireworkVideos {
            ...ComponentStackFireworkVideosFields
          }
          ... on AMAPI_ComponentStackMerchandisingBlock {
            ...ComponentStackMerchandisingBlockFields
          }
          ... on AMAPI_ComponentStackSpacer {
            ...ComponentStackSpacerFields
          }
          ... on AMAPI_ComponentStackCtas {
            ...ComponentStackCtasFields
          }
          ... on AMAPI_ComponentStackContactSection {
            ...ComponentStackContactSectionFields
          }
          ... on AMAPI_ComponentStackTrustSignalContentBlock {
            ...ComponentStackTrustSignalContentBlockFields
          }
          ... on AMAPI_ComponentStackCardScrollBlock {
            ...ComponentStackCardScrollBlockFields
          }
          ... on AMAPI_ComponentStackProductPimDimensions {
            ...ComponentStackProductPimDimensionsFields
          }
          ... on AMAPI_ComponentStackSpecificationTabs {
            ...ComponentStackSpecificationTabsFields
          }
        }
      }
    }
  }
`;
