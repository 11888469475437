import gql from "graphql-tag";
import { AllBasicFragments } from "./fragments";
import { AllFragments as AllStackFragments } from "./stack_fragments";

export const PageQuery = gql`
  ${AllBasicFragments}
  ${AllStackFragments}
  query PAGE_QUERY($locale: String, $pageSlug: String!) {
    pages(locale: $locale, where: { slug: $pageSlug }) {
      stack {
        __typename
        ... on ComponentStackSeo {
          ...ComponentStackSeoFields
        }
        ... on ComponentStackPressLogos {
          ...ComponentStackPressLogosFields
        }
        ... on ComponentStackAffirmSection {
          id
          included
          product {
            title
          }
        }
        ... on ComponentStackTwoColumnDescription {
          id
          primary
          invert
          body
        }
        ... on ComponentStackOkendoReview {
          id
          okendoReview {
            collectionId
            productId
          }
        }
        ... on ComponentStackShowroomsFinder {
          enable
        }
        ... on ComponentStackMasonryHero {
          id
          title
          pretitle
          pretitleModalText
          pretitleModalMedia {
            url
            alternativeText
          }
          description
          buttonCta
          buttonCtaLink
          videoCta
          videoCtaLink
          galleryItems {
            media {
              url
              alternativeText
            }
          }
        }
        ... on ComponentStackInspirationGallery {
          id
          title
          subtitle
          galleryItems {
            inspirationObject {
              coverPhoto {
                url
                height
                width
                alternativeText
              }
              caption
              customerName
              customerLabel
              customerLocation
              visitableShowroom
              ctaPrimary
              ctaPrimaryLink
              ctaSecondary
              ctaSecondaryLink
              ctaPrimaryTooltip
              ctaSecondaryTooltip
              products {
                displayVariantSlug
                product {
                  title
                  slug
                  currency {
                    name
                    code
                    symbol
                  }
                  variants {
                    slug
                    title
                    price
                    photos {
                      url
                      alternativeText
                    }
                  }
                }
                customProduct {
                  title
                  price
                  slug
                  photo {
                    url
                    alternativeText
                  }
                }
              }
            }
          }
        }
        ... on ComponentStackFaqSection {
          id
          faqs {
            id
            question
            answer
            priority
          }
        }
        ... on ComponentStackLeverJobPositionsBlock {
          id
          primary
          secondary
          tertiary
        }
        ... on ComponentStackMediaGallery {
          primary
          secondary
          gridImagesNumber
          medias: media {
            url
            alternativeText
            caption
            height
            width
            name
          }
          sharedGalleries {
            images {
              url
              alternativeText
              caption
              height
              width
              name
            }
          }
        }
        ... on ComponentStackPromotedCatalogBlock {
          ...ComponentStackPromotedCatalogBlockFields
        }
        ... on ComponentStackFeaturedParagraph {
          ...ComponentStackFeaturedParagraphFields
        }
        ... on ComponentStackPromotedTabbedFeature {
          ...ComponentStackPromotedTabbedFeatureFields
        }
        ... on ComponentStackTwoColumnsContentBlock {
          ...ComponentStackTwoColumnsContentBlockFields
        }
        ... on ComponentStackSingleAccordion {
          id
          title
          content
        }
        ... on ComponentStackTabSelector {
          id
          addToCardButton
          ctas {
            text
            location
            event
          }
        }
        ... on ComponentStackMediaCarousel {
          ...ComponentStackMediaCarouselFields
        }
        ... on ComponentStackMultiColumnContentBlock {
          ...ComponentStackMultiColumnContentBlockFields
        }
        ... on ComponentStackStaticTestimonialBlock {
          ...ComponentStackStaticTestimonialBlockFields
        }
        ... on ComponentStackStickyBar {
          ...ComponentStackStickyBarFields
        }
        ... on ComponentStackHero {
          ...ComponentStackHeroFields
        }
        ... on ComponentStackAccordion {
          ...ComponentStackAccordionFields
        }
        ... on ComponentStackTextBlock {
          ...ComponentStackTextBlockFields
        }
        ... on ComponentStackFireworkVideos {
          ...ComponentStackFireworkVideosFields
        }
        ... on ComponentStackMerchandisingBlock {
          ...ComponentStackMerchandisingBlockFields
        }
        ... on ComponentStackSpacer {
          ...ComponentStackSpacerFields
        }
        ... on ComponentStackCtas {
          ...ComponentStackCtasFields
        }
        ... on ComponentStackContactSection {
          ...ComponentStackContactSectionFields
        }
        ... on ComponentStackTrustSignalContentBlock {
          ...ComponentStackTrustSignalContentBlockFields
        }
        ... on ComponentStackCardScrollBlock {
          ...ComponentStackCardScrollBlockFields
        }
        ... on ComponentStackProductPimDimensions {
          ...ComponentStackProductPimDimensionsFields
        }
        ... on ComponentStackSpecificationTabs {
          ...ComponentStackSpecificationTabsFields
        }
      }
    }
  }
`;
