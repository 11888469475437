import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";

import withClientRouteLoading from "../hoc/withClientRouteLoading/withClientRouteLoading";
import { Button, SelectBox, Spinner } from "../components";

import withLayout from "../hoc/withLayout/withLayout";
import { ProductQuery } from "../utils/ssr/product_query";
import { PageQuery } from "../utils/ssr/page_query";
import { CatalogQuery } from "../utils/ssr/catalog_query";

import PDPPage from "../templates/ProductDetailsStack/ProductDetailsStack";
import Page from "../templates/Page/Page";
import CatalogPage from "../templates/Catalog/CatalogPage";

const transformStack = stack => {
  if (!stack) return null;
  return stack.map(el => {
    const amapiComponent = `AMAPI_${el.__typename}`;
    return { ...el, __typename: amapiComponent };
  });
};

const StackPreview = ({ pageContext }) => {
  // PDP PAGE STATES
  const [refetchPdpPageFlag, setRefetchPdpPageFlag] = useState(0);
  const [pdpPageData, setPdpPageData] = useState<any>([]);
  // PAGE STATES
  const [refetchPageFlag, setRefetchPageFlag] = useState(0);
  const [pageData, setPageData] = useState<any>([]);

  // CATALOG PAGE STATES
  const [refetchCatalogPageFlag, setRefetchCatalogPageFlag] = useState(0);
  const [catalogPageData, setCatalogPageData] = useState<any>([]);

  // GENERAL STATES
  const [loading, setLoading] = useState(false);
  const [slug, setSlug] = useState<string>();
  const [isEmpty, setIsEmpty] = useState(false);

  const [pageType, setPageType] = useState<"PDP" | "PAGE" | "CATALOG">();

  // PDP Query
  const [
    getPdpData,
    {
      loading: loadingPdp,
      error: errorPdp,
      data: pdpData,
      refetch: refetchPdp,
    },
  ] = useLazyQuery(ProductQuery, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (pdpData?.products?.length === 0) {
      setLoading(false);
      setIsEmpty(true);
    }
    if (pdpData?.products?.length > 0) {
      setLoading(false);
      setPageData([]);
      let product = [
        {
          ...pdpData.products[0],
          stackPage: {
            stack: transformStack(pdpData.products[0].stackPage?.stack),
          },
          stackComponents: transformStack(pdpData.products[0].stackComponents),
        },
      ];
      setPdpPageData(product);
    }
  }, [pdpData, refetchPdpPageFlag]);

  // Page Query
  const [
    getPageData,
    { loading: loadingPage, error: errorPage, data, refetch: refetchPage },
  ] = useLazyQuery(PageQuery, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (data?.pages.length === 0) {
      setLoading(false);
      setIsEmpty(true);
    }
    if (data?.pages.length > 0) {
      setLoading(false);
      setPdpPageData([]);
      let page = [
        {
          ...data.pages[0],
          stack: transformStack(data.pages[0].stack),
        },
      ];

      setPageData(page);
    }
  }, [data, refetchPageFlag]);

  // Catalog Query
  const [
    getCatalogPageData,
    {
      loading: loadingCatalogPage,
      error: errorCatalogPage,
      data: catalogData,
      refetch: refetchCatalogPage,
    },
  ] = useLazyQuery(CatalogQuery, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (catalogData?.catalogs.length === 0) {
      setLoading(false);
      setIsEmpty(true);
    }
    if (catalogData?.catalogs.length > 0) {
      setLoading(false);
      setCatalogPageData([]);
      let catalog = [
        {
          ...catalogData.catalogs[0],
          stack: transformStack(catalogData.catalogs[0].stack),
        },
      ];

      setCatalogPageData(catalog);
    }
  }, [catalogData, refetchCatalogPageFlag]);

  const handleClick = () => {
    if (!slug) return;
    setLoading(true);
    setIsEmpty(false);
    switch (pageType) {
      case "PDP":
        getPdpData({
          context: { clientName: "cms" },
          variables: {
            productSlug: slug,
            locale: "en",
          },
        });
        break;
      case "PAGE":
        getPageData({
          context: { clientName: "cms" },
          variables: {
            pageSlug: slug,
            locale: "en",
          },
        });
        break;
      case "CATALOG":
        getCatalogPageData({
          context: { clientName: "cms" },
          variables: {
            catalogSlug: slug,
            locale: "en",
          },
        });
        break;
      default:
        break;
    }
  };

  const renderTemplate = pageType => {
    switch (pageType) {
      case "PDP":
        if (pdpPageData.length > 0) {
          return (
            <PDPPage
              pageContext={{
                ...pageContext,
                variantSlug: pdpPageData[0].variants[0].slug,
              }}
              data={{
                alamedaapi: {
                  product: pdpPageData[0],
                  outOfStockNotificationRequest: {},
                },
              }}
            />
          );
        }
        break;

      case "PAGE":
        if (pageData.length > 0) {
          return (
            <Page
              pageContext={{
                ...pageContext,
              }}
              data={{
                alamedaapi: {
                  pages: pageData,
                },
              }}
            />
          );
        }
        break;

      case "CATALOG":
        if (catalogPageData.length > 0) {
          return (
            <CatalogPage
              pageContext={{
                ...pageContext,
              }}
              location={{
                pathname: "",
                href: "",
              }}
              data={{
                alamedaapi: {
                  catalog: catalogPageData[0],
                },
              }}
            />
          );
        }
        break;

      default:
        break;
    }
  };

  const handleKeypress = e => {
    if (e.keyCode === 13 || e.charCode === 13) {
      handleClick();
    }
  };

  return (
    <>
      <div className="border-b border-solid border-secondary-color px-4 py-10 md:px-10">
        <p className="p-styles mb-5">
          Select the kind and slug that you want to preview:
        </p>

        <div className="align-center mb-5 flex gap-x-10">
          <SelectBox
            options={["PDP", "PAGE", "CATALOG"]}
            unsetOption={null}
            required={false}
            onChange={e => {
              setPageType(e.target.value);
            }}
            value={pageType!}
            className="rounded-md"
          />
          <input
            onChange={e => setSlug(e.target.value)}
            type="text"
            className="w-1/2 rounded-md border border-solid border-outer-gray pl-7 pr-12 focus:border-outer-slate-blue focus:ring-outer-slate-blue"
            onKeyDown={handleKeypress}
          />
          <Button onClick={handleClick} disabled={!slug || !pageType}>
            Preview
          </Button>
        </div>
      </div>
      <div>
        {errorPdp || errorPage || errorCatalogPage ? (
          <p className="p-14 text-[red]">
            This is an error, please contact support
          </p>
        ) : (
          <>
            {isEmpty && (
              <p className="p-14 text-[red]">
                This page doesn't exists or doesn't have stack components
              </p>
            )}
            {loading ? <Spinner /> : renderTemplate(pageType)}
          </>
        )}
      </div>
    </>
  );
};

export default withLayout(withClientRouteLoading(StackPreview));
