import React from "react";
import { JsonLd } from "gatsby-plugin-next-seo";
import { Organization, WebSite, SiteNavigationElement } from "schema-dts";

const HomeStructuredData = () => {
  return (
    <>
      <JsonLd<Organization>
        json={{
          "@context": "https://schema.org",
          "@type": "Organization",
          name: "Outer",
          legalName: "Outer, Inc.",
          description:
            "We've used 20+ years of product design experience to create the perfect outdoor sofa that is timeless, durable and thoughtfully designed.",
          url: "https://liveouter.com/",
          logo: "https://liveouter.com/images/logo.svg",
          foundingDate: "2019",
          address: {
            "@type": "PostalAddress",
            streetAddress: "2216 Main Street",
            addressLocality: "Santa Monica",
            addressRegion: "CA",
            postalCode: "90405",
            addressCountry: "USA",
          },
          sameAs: [
            "https://www.facebook.com/liveouter/",
            "https://www.instagram.com/liveouter/",
            "https://twitter.com/liveouter/",
            "https://www.pinterest.com/liveouter/",
          ],
        }}
        defer={false}
      />
      <JsonLd<WebSite>
        json={{
          "@context": "https://schema.org",
          "@type": "WebSite",
          name: "Outer | The Perfect Outdoor Sofa Is Now Within Reach.",
          url: "https://liveouter.com/",
        }}
        defer={false}
      />
      {/* When dynamic nav is ready - this should pull from that */}
      <JsonLd<SiteNavigationElement>
        json={[
          {
            "@context": "http://schema.org",
            "@type": "siteNavigationElement",
            name: "Sofas & Sectionals",
            url: "https://liveouter.com/products",
          },
          {
            "@context": "http://schema.org",
            "@type": "siteNavigationElement",
            name: "Outdoor Rugs",
            url: "https://liveouter.com/explore-1188-eco-friendly-outdoor-rugs",
          },
          {
            "@context": "http://schema.org",
            "@type": "siteNavigationElement",
            name: "Covers",
            url: "https://liveouter.com/explore/all-weather-covers",
          },
          {
            "@context": "http://schema.org",
            "@type": "siteNavigationElement",
            name: "Find A Showroom",
            url: "https://liveouter.com/showrooms",
          },
          {
            "@context": "http://schema.org",
            "@type": "siteNavigationElement",
            name: "Blog",
            url: "https://blog.liveouter.com/",
          },
          {
            "@context": "http://schema.org",
            "@type": "siteNavigationElement",
            name: "Our Story",
            url: "https://liveouter.com/about",
          },
        ]}
        defer={false}
      />
    </>
  );
};

export default HomeStructuredData;
